:root {
    scroll-behavior: smooth;
}
body {
    font-family: Poppins;
    color: #444;
}
a {
    color: #4154f1;
    text-decoration: none;
}
a:hover {
    color: #717ff5;
    text-decoration: none;
}
h1, h2, h3, h4, h5, h6 {
    font-family: Poppins;
}
#hero p {
    margin: 0 auto;
    padding: 0;
    font-size: 18px;
    line-height: 42px;
    color: #000;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0;
    width: 80%;
}
section {
    padding: 0px 0px;
    overflow: hidden;
}
.section-header {
    text-align: center;
    padding: 50px 0px;
}
.section-header h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4em;
    letter-spacing: -.4px;
    font-family: Poppins;
    color: #23203f;
    margin: 0 0 25px 0;
}
.section-header p {
    margin: 0 auto;
    padding: 0;
    font-size: 18px;
    line-height: 42px;
    color: #000;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0;
    width: 60%
}
.mob_show{
	display: none;
}
.desk_show{
	display: block;
}
.link_footer{
	color: #ffffff !important;
}
.linK_footer:hover{
	color: #ffd524 !important;
}
.video_custom{
	width: 60%;
}
.price_btn_div button{
    background: #ffffff;
    margin: 0px 5px;
}
.acctive_btn{
    line-height: 0 !important;
    padding: 30px 55px !important;
    border-radius: 40px !important;
    border: none !important;
    transition: .5s !important;
    color: #fff !important;
    background: #ff443c !important;
    box-shadow: 0 5px 30px rgb(65 84 241 / 40%) !important;

}
.btn_price{
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    color: #4154f1;
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-family: Nunito, sans-serif;
    font-weight: 600;
    transition: .3s;
    border: 1px solid #4154f1;
}
.cnt_two_way_msg{
    display: flex;
    justify-content: center;
}
.plan_byon_img{
    max-height: 85px !important;
}
.enterprise_sec_ht{
    margin-bottom: 80px !important;
}
@media screen and (min-width :320px) and (max-width :479px) {
.features .feature-icons .content .icon-box h4 {
    font-size: 12px!important;
    font-weight: 300!important;
    }
    .cnt_two_way_msg{
        display: flex;
        justify-content: left !important;
    }
}
@media screen and (min-width :480px) and (max-width :768px) {
    .features .feature-icons .content .icon-box h4 {
        font-size: 14px!important;
        font-weight: 300!important;
        }
    }
@media (max-width:768px) {
.section-header p {
    font-size: 18px;
    line-height: 32px;
    width: 100%;
}
#hero p {
    font-size: 18px;
    line-height: 32px;
    width: 100%;
}
.video_custom{
	width: 90%;
}
.mob_txt_cntr{
	text-align: center !important;
}
.purple_txt_clr p {
	font-size: 18px !important;
    line-height: 32px !important;
    width: 100% !important;
}
.mob_ms_5 {
    margin-left: 3rem!important;
    text-align: center;
}
.how-it-works h3 {
    font-size: 30px!important;
    margin: 0!important;
}
.section-header h2 {
    font-size: 30px;
}
.footer .footer-newsletter h4 {
    font-size: 30px!important;
    width: 80%!important;
}
.footer .footer-newsletter p {
    font-size: 18px!important;
    width: 80%!important;
}
.btn-get-started span {
    font-size: 12px!important;
}
.btn-get-started {
    padding: 15px 20px !important;
}
.mob_f_sze_13{
	font-size: 13px !important;
}
.play_store_img{
	width: 100% !important;
    max-width: 15% !important;
}
.mob_pad_btn{
	padding: 20px 35px !important;
}
.team .member {
	background: none !important;

}
.mob_show{
	display: block !important;
}
.desk_show{
	display: none !important;
}
.mob_btm_0{
	padding-bottom: 0px !important;
}
/* .features .feature-icons .content .icon-box h4 {
    font-size: 14px !important;
    font-weight: 300 !important;
} */
iframe {
    display: block;
    width: 560px!important;
    height: 315px!important;
}
.justify-content-end {
    justify-content: flex-start!important;
}
.two-Way-icon img {
    display: none;
}
.two-Way-mob-img {
    display: none;
}
}.breadcrumbs {
    padding: 15px 0;
    background: #012970;
    min-height: 40px;
    margin-top: 82px;
    color: #fff;
}

@media (max-width:992px) {
    .breadcrumbs {
    margin-top: 57px;
}
.hide_small {
	display: none !important; 
}
}.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 500;
}
.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
}
.breadcrumbs ol a {
    color: #fff;
    transition: .3s;
}
.breadcrumbs ol a:hover {
    text-decoration: underline;
}
.breadcrumbs ol li+li {
    padding-left: 10px;
}
.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #8894f6;
    content: "/"}
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #4154f1;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all .4s;
}
.back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
}
.back-to-top:hover {
    background: #6776f4;
    color: #fff;
}
.back-to-top.active {
    visibility: visible;
    opacity: 1;
}
.how-it-works h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4em;
    margin: 0 0 5px 0;
}
.how-it-works p {
    font-size: 18px;
    line-height: 1.8em;
    font-weight: 300;
    letter-spacing: 0;
}
.clr_black {
    color: #000!important;
}
.clr_yellow {
    color: #ffd524!important;
}
.clr_white {
    color: #fff!important;
}
.feature_icon img {
    width: auto;}
@media screen and (max-width:768px) {
    [data-aos-delay] {
    transition-delay: 0!important;
}
}
/* @media (min-width:1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
    }
} */

@media (min-width:1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1400px;
    }
}
.header {
    transition: all .5s;
    z-index: 997;
    padding: 20px 0;
}
.header.header-scrolled {
    background: #ffffff;
    padding: 15px 0;
    box-shadow: 0 2px 20px rgb(1 41 112 / 10%);
}
.header .logo {
    line-height: 0;
}
.header .logo img {
    max-height: auto;
    margin-right: 6px;
}
.header .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: Nunito, sans-serif;
    margin-top: 3px;
}
.navbar {
    padding: 0;
}
.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}
.navbar li {
    position: relative;
}
.navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #553e93;
    white-space: nowrap;
    transition: .3s;
}
.navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}
.navbar .active, .navbar .active:focus, .navbar a:hover, .navbar li:hover>a {
    color: #ff443c;
}
.navbar .getstarted, .navbar .getstarted:focus {
    background: #4154f1;
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff;
}
.navbar .getstarted:focus:hover, .navbar .getstarted:hover {
    color: #fff;
    background: #5969f3;
}
.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, .25);
    transition: .3s;
    border-radius: 4px;
}
.navbar .dropdown ul li {
    min-width: 200px;
}
.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
}
.navbar .dropdown ul a i {
    font-size: 12px;
}
.navbar .dropdown ul .active:hover, .navbar .dropdown ul a:hover, .navbar .dropdown ul li:hover>a {
    color: #4154f1;
}
.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}
.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}
@media (max-width:1366px) {
    .navbar .dropdown .dropdown ul {
    left: -90%}
.navbar .dropdown .dropdown:hover>ul {
    left: -100%}
}.mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: .5s;
}
.mobile-nav-toggle.bi-x {
    color: #fff;
}
@media (max-width:991px) {
    .mobile-nav-toggle {
    display: block;
}
.navbar ul {
    display: none;
}
}.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, .9);
    transition: .3s;
}
.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}
.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: .3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #012970;
}
.navbar-mobile .active, .navbar-mobile a:hover, .navbar-mobile li:hover>a {
    color: #4154f1;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
}
.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, .25);
}
.navbar-mobile .dropdown ul li {
    min-width: 200px;
}
.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}
.navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul li:hover>a {
    color: #fff;
}
.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}
.hero {
    width: 100%;
    height: auto;
    /* height: 100vh; */
    background-size: cover;
}
#hero{
    margin-top: 110px;
}
.hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 900;
    font-family: Poppins;
    color: #553e93;
    line-height: 1.4em;
}
.hero h2 {
    color: #444;
    margin: 15px 0 0 0;
    font-size: 26px;
}
.btn-get-started {
    /*margin-top: 30px;
    margin-bottom: 30px;*/
    line-height: 0;
    padding: 30px 55px;
    border-radius: 40px;
    transition: .5s;
    color: #fff;
    background: #ff443c;
    box-shadow: 0 5px 30px rgba(65, 84, 241, .4);
}
.btn-get-started:hover {
    color: #000;
    background: #ffd524;
}
.btn-get-started span {
    font-family: Nunito, sans-serif;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 1px;
}
.btn-get-started i {
    margin-left: 5px;
    font-size: 18px;
    transition: .3s;
}
.btn-get-started:hover i {
    transform: translateX(5px);
}
.btn-get-started-section {
    line-height: 0;
    padding: 30px 54px;
    border-radius: 40px;
    transition: .5s;
    color: #fff;
    background: #ff443c;
    box-shadow: 0 5px 30px rgba(65, 84, 241, .4);
}
.btn-get-started-section:hover {
    color: #fff;
    background: #553e93;
}
.btn-get-started-section span {
    font-family: Nunito, sans-serif;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 1px;
}
.btn-get-started-section i {
    margin-left: 5px;
    font-size: 22px;
    transition: .3s;
}
.btn-get-started-section:hover i {
    transform: translateX(5px);
}
.btn-get-started-footer {
    line-height: 0;
    padding: 21px 55px;
    border-radius: 40px;
    transition: .5s;
    color: #fff;
    background: #ff443c;
    box-shadow: 0 5px 30px rgb(65 84 241 / 40%);
}
.btn-get-started-footer span {
    font-family: Nunito, sans-serif;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 1px;
}
.btn-get-started-footer:hover {
    color: #000;
    background: #ffd524;
}
.hero .hero-img {
    text-align: right;
}
.bg_purble {
    background: #553e93;
}
.bg_lgt_purble {
    background: #9570dd;
}
.purple_txt_clr p {
    color: #fff!important;
    width: 60%;
    margin: 0 auto;
    line-height: 42px;
    font-size: 24px;
}
@media (min-width:1024px) {
    .hero {
    background-attachment: fixed;
}
}@media (max-width:991px) {
    .hero {
    height: auto;
    padding: 120px 0 60px 0;
}
#hero {
    margin-top: 10px;
}
.hero .hero-img {
    text-align: center;
    margin-top: 80px;
}
.hero .hero-img img {
    width: 80%}
}@media (max-width:768px) {
    .hero {
    text-align: center;
}
.hero h1 {
    font-size: 32px;
}
.hero h2 {
    font-size: 24px;
}
.hero .hero-img img {
    width: 100%}
}.about .content {
    background-color: #f6f9ff;
    padding: 40px;
}
.about h3 {
    font-size: 14px;
    font-weight: 700;
    color: #4154f1;
    text-transform: uppercase;
}
.about h2 {
    font-size: 24px;
    font-weight: 700;
    color: #012970;
}
.about p {
    margin: 15px 0 30px 0;
    line-height: 24px;
}
.about .btn-read-more {
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: .5s;
    color: #fff;
    background: #4154f1;
    box-shadow: 0 5px 25px rgba(65, 84, 241, .3);
}
.about .btn-read-more span {
    font-family: Nunito, sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}
.about .btn-read-more i {
    margin-left: 5px;
    font-size: 18px;
    transition: .3s;
}
.about .btn-read-more:hover i {
    transform: translateX(5px);
}
.values .box {
    padding: 30px;
    box-shadow: 0 0 5px rgba(1, 41, 112, .08);
    text-align: center;
    transition: .3s;
    height: 100%}
.values .box img {
    padding: 30px 50px;
    transition: .5s;
    transform: scale(1.1);
}
.values .box h3 {
    font-size: 24px;
    color: #012970;
    font-weight: 700;
    margin-bottom: 18px;
}
.values .box:hover {
    box-shadow: 0 0 30px rgba(1, 41, 112, .08);
}
.values .box:hover img {
    transform: scale(1);
}
.counts {
    padding: 70px 0 60px;
}
.counts .count-box {
    display: flex;
    align-items: center;
    padding: 30px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 30px rgba(1, 41, 112, .08);
}
.counts .count-box i {
    font-size: 42px;
    line-height: 0;
    margin-right: 20px;
    color: #4154f1;
}
.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #0b198f;
}
.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: Nunito, sans-serif;
    font-size: 14px;
}
.features .feature-box {
    padding: 12px 20px;
    transition: .3s;
    height: 100%;
    background: #eef;
}
.features .feature-box h3 {
    font-size: 18px!important;
    color: #012970;
    font-weight: 300;
    margin: 0;
}
.features .feature-box i {
    line-height: 0;
    background: #553e93;
    padding: 4px;
    margin-right: 10px;
    font-size: 24px;
    color: #ffffff;
    border-radius: 3px;
    transition: .3s;
}
.features .feature-box:hover i {
    background: #553e93;
    color: #fff;
}
.features .feture-tabs {
    margin-top: 120px;
}
.features .feture-tabs h3 {
    color: #012970;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
}
@media (max-width:768px) {
    .features .feture-tabs h3 {
    font-size: 28px;
}
}.features .feture-tabs .nav-pills {
    border-bottom: 1px solid #eee;
}
.features .feture-tabs .nav-link {
    background: 0 0;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #012970;
    padding: 12px 0;
    margin-right: 25px;
    margin-bottom: -2px;
    border-radius: 0;
}
.features .feture-tabs .nav-link.active {
    color: #4154f1;
    border-bottom: 3px solid #4154f1;
}
.features .feture-tabs .tab-content h4 {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
    color: #012970;
}
.features .feture-tabs .tab-content i {
    font-size: 24px;
    line-height: 0;
    margin-right: 8px;
    color: #4154f1;
}
.features .feature-icons {
    margin-top: 20px;
}
.features .feature-icons h3 {
    color: #012970;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}
@media (max-width:768px) {
    .features .feature-icons h3 {
    font-size: 28px;
}
}.features .feature-icons .content .icon-box {
    display: flex;
}
.features .feature-icons .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: #fff;
}
.features .feature-icons .content .icon-box i {
    font-size: 44px;
    line-height: 44px;
    color: #fff;
    margin-right: 15px;
    margin-left: 15px;
}
.features .feature-icons .content .icon-box p {
    font-size: 15px;
    color: #fff;
}
.services .service-box {
    box-shadow: 0 0 30px rgba(1, 41, 112, .08);
    height: 100%;
    padding: 60px 30px;
    text-align: center;
    transition: .3s;
    border-radius: 5px;
}
.services .service-box .icon {
    font-size: 36px;
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: .3s;
}
.services .service-box h3 {
    color: #444;
    font-weight: 700;
    padding-bottom: 15px;
}
.services .service-box .read-more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 20px;
}
.services .service-box .read-more i {
    line-height: 0;
    margin-left: 5px;
    font-size: 18px;
}
.services .service-box.white_clr {
    border-bottom: 3px solid #553e93;
}
.services .service-box.purple_clr:hover {
    background: #553e93d6;
}
.services .service-box.white_clr:hover {
    background: #ffffff;
}
.services .service-box.blue {
    border-bottom: 3px solid #2db6fa;
}
.services .service-box.blue .icon {
    color: #2db6fa;
    background: #dbf3fe;
}
.services .service-box.blue .read-more {
    color: #2db6fa;
}
.services .service-box.blue:hover {
    background: #f5f4ff;
}
.services .service-box.white_orange {
    border-bottom: 3px solid #f68c09;
}
.services .service-box.orange .icon {
    color: #f68c09;
    background: #fde3c4;
}
.services .service-box.orange .read-more {
    color: #f68c09;
}
.services .service-box.white_orange:hover {
    background: #ffffff;
}
.services .service-box.green {
    border-bottom: 3px solid #08da4e;
}
.services .service-box.green .icon {
    color: #08da4e;
    background: #cffddf;
}
.services .service-box.green .read-more {
    color: #08da4e;
}
.services .service-box.green:hover {
    background: #08da4e;
}
.services .service-box.red {
    border-bottom: 3px solid #e9222c;
}
.services .service-box.red .icon {
    color: #e9222c;
    background: #fef7f8;
}
.services .service-box.red .read-more {
    color: #e9222c;
}
.services .service-box.red:hover {
    background: #e9222c;
}
.services .service-box.purple {
    border-bottom: 3px solid #b50edf;
}
.services .service-box.purple .icon {
    color: #b50edf;
    background: #f8e4fd;
}
.services .service-box.purple .read-more {
    color: #b50edf;
}
.services .service-box.purple:hover {
    background: #b50edf;
}
.services .service-box.pink {
    border-bottom: 3px solid #f51f9c;
}
.services .service-box.pink .icon {
    color: #f51f9c;
    background: #feecf7;
}
.services .service-box.pink .read-more {
    color: #f51f9c;
}
.services .service-box.pink:hover {
    background: #f51f9c;
}
.services .service-box:hover .read-more, .services .service-box:hover h3, .services .service-box:hover p {
    color: rgb(0, 0, 0);
}
.services .service-box:hover .icon {
    background: #fff;
}
.pricing .box {
    padding: 40px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0 0 30px rgba(1, 41, 112, .08);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: .3s;
}
.pricing .box:hover {
    transform: scale(1.1);
    box-shadow: 0 0 30px rgba(1, 41, 112, .1);
}
.pricing h3 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
    height: 75px;
}
.pricing .price {
    font-size: 36px;
    color: #444;
    font-weight: 600;
    font-family: Poppins, sans-serif;
}
.pricing .price sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}
.pricing .price span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}
.pricing img {
    padding: 30px 40px;
}
.pricing ul {
    padding: 0;
    list-style: none;
    color: #444;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 25px;
    height: 435px;
}
.pricing ul li {
    padding-bottom: 10px;
}
.pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
}
.pricing .btn-buy {
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    color: #fff;
    transition: none;
    font-size: 16px;
    background: #ff443c;
    font-weight: 400;
    font-family: Nunito, sans-serif;
    font-weight: 600;
    transition: .3s;
    box-shadow: 0 5px 30px rgb(65 84 241 / 40%);
}
.pricing .btn-buy:hover {
    color: #fff;
    background: #553e93;
}
.pricing .featured {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #4154f1;
    color: #fff;
}
.faq .accordion-collapse {
    border: 0;
}
.faq .accordion-button {
    padding: 15px 15px 20px 0;
    font-weight: 600;
    border: 0;
    font-size: 18px;
    color: #444;
    text-align: left;
}
.faq .accordion-button:focus {
    box-shadow: none;
}
.faq .accordion-button:not(.collapsed) {
    background: 0 0;
    color: #4154f1;
    border-bottom: 0;
}
.faq .accordion-body {
    padding: 0 0 25px 0;
    border: 0;
}
.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    background: #fff;
    border-radius: 50px;
    padding: 2px 15px;
}
.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    color: #444;
    margin: 0 4px 8px 4px;
    transition: .3s;
    border-radius: 50px;
    border: 1px solid #fff;
}
.portfolio #portfolio-flters li.filter-active, .portfolio #portfolio-flters li:hover {
    color: #4154f1;
    border-color: #4154f1;
}
.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}
.portfolio .portfolio-wrap {
    transition: .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(255, 255, 255, .75);
}
.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(255, 255, 255, .75);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out .3s;
    z-index: 2;
    opacity: 0;
}
.portfolio .portfolio-wrap img {
    transition: 1s;
}
.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out .3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid rgba(1, 41, 112, .2);
    border-left: 3px solid rgba(1, 41, 112, .2);
    transition: all .5s ease 0s;
    z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid rgba(1, 41, 112, .2);
    border-right: 3px solid rgba(1, 41, 112, .2);
    transition: all .5s ease 0s;
    z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #012970;
    font-weight: 700;
}
.portfolio .portfolio-wrap .portfolio-info p {
    color: #012970;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}
.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}
.portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    background: #4154f1;
    margin: 10px 2px;
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .3s;
}
.portfolio .portfolio-wrap .portfolio-links a i {
    font-size: 24px;
    line-height: 0;
}
.portfolio .portfolio-wrap .portfolio-links a:hover {
    background: #5969f3;
}
.portfolio .portfolio-wrap:hover img {
    transform: scale(1.1);
}
.portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
}
.portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
}
.portfolio-details {
    padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
    width: 100%}
.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #4154f1;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #4154f1;
}
.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0 0 30px rgba(1, 41, 112, .08);
}
.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}
.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}
.portfolio-details .portfolio-description {
    padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
    padding: 0;
}
.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px;
    margin: 40px 30px;
    box-shadow: 0 0 20px rgba(1, 41, 112, .1);
    background: #fff;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: .3s;
}
.testimonials .testimonial-item .stars {
    margin-bottom: 15px;
}
.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}
.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
}
.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0 5px 0;
    color: #111;
}
.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}
.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}
.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #4154f1;
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #4154f1;
}
.testimonials .swiper-slide {
    opacity: .3;
}
@media (max-width:1199px) {
    .testimonials .swiper-slide-active {
    opacity: 1;
}
.testimonials .swiper-pagination {
    margin-top: 0;
}
}@media (min-width:1200px) {
    .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
}
}
.team {
    background: #ffffff;
    padding: 10px 0;
}
.team .member {
    overflow: hidden;
    text-align: center;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0 30px rgba(1, 41, 112, .08);
    transition: .3s;
}
.team .member .member-img {
    position: relative;
    overflow: hidden;
}
.team .member .member-img:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-size: contain;
    z-index: 1;
}
.team .member .social {
    position: absolute;
    right: -100%;
    top: 30px;
    opacity: 0;
    border-radius: 4px;
    transition: .5s;
    background: rgba(255, 255, 255, .3);
    z-index: 2;
}
.team .member .social a {
    transition: color .3s;
    color: rgba(1, 41, 112, .5);
    margin: 15px 12px;
    display: block;
    line-height: 0;
    text-align: center;
}
.team .member .social a:hover {
    color: rgba(1, 41, 112, .8);
}
.team .member .social i {
    font-size: 18px;
}
.team .member .member-info {
    padding: 10px 15px 20px 15px;
}
.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #012970;
}
.team .member .member-info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #aaa;
}
.team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: #5e5e5e;
}
.team .member:hover {
    transform: scale(1.08);
    box-shadow: 0 0 30px rgba(1, 41, 112, .1);
}
.team .member:hover .social {
    right: 8px;
    opacity: 1;
}
.clients .clients-slider .swiper-slide img {
    opacity: .5;
    transition: .3s;
}
.clients .clients-slider .swiper-slide img:hover {
    opacity: 1;
}
.clients .clients-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #4154f1;
}
.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #4154f1;
}
.recent-blog-posts .post-box {
    box-shadow: 0 0 30px rgba(1, 41, 112, .08);
    transition: .3s;
    height: 100%;
    overflow: hidden;
    padding: 30px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
}
.recent-blog-posts .post-box .post-img {
    overflow: hidden;
    margin: -30px -30px 15px -30px;
    position: relative;
}
.recent-blog-posts .post-box .post-img img {
    transition: .5s;
}
.recent-blog-posts .post-box .post-date {
    font-size: 16px;
    font-weight: 600;
    color: rgba(1, 41, 112, .6);
    display: block;
    margin-bottom: 10px;
}
.recent-blog-posts .post-box .post-title {
    font-size: 24px;
    color: #012970;
    font-weight: 700;
    margin-bottom: 18px;
    position: relative;
    transition: .3s;
}
.recent-blog-posts .post-box .readmore {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 1;
    transition: .3s;
}
.recent-blog-posts .post-box .readmore i {
    line-height: 0;
    margin-left: 4px;
    font-size: 18px;
}
.recent-blog-posts .post-box:hover .post-title {
    color: #4154f1;
}
.recent-blog-posts .post-box:hover .post-img img {
    transform: rotate(6deg) scale(1.2);
}
.contact .info-box {
    color: #444;
    background: #fafbff;
    padding: 30px;
}
.contact .info-box i {
    font-size: 38px;
    line-height: 0;
    color: #4154f1;
}
.contact .info-box h3 {
    font-size: 20px;
    color: #012970;
    font-weight: 700;
    margin: 20px 0 10px 0;
}
.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}
.contact .php-email-form {
    background: #fafbff;
    padding: 30px;
    height: 100%}
.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}
.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}
.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}
.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 0;
}
.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #4154f1;
}
.contact .php-email-form input {
    padding: 10px 15px;
}
.contact .php-email-form textarea {
    padding: 12px 15px;
}
.contact .php-email-form button[type=submit] {
    background: #4154f1;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: .4s;
    border-radius: 4px;
}
.contact .php-email-form button[type=submit]:hover {
    background: #5969f3;
}
@-webkit-keyframes animate-loading {
    0% {
    transform: rotate(0);
}
100% {
    transform: rotate(360deg);
}
}@keyframes animate-loading {
    0% {
    transform: rotate(0);
}
100% {
    transform: rotate(360deg);
}
}.blog {
    padding: 40px 0 20px 0;
}
.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
}
.blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}
.blog .entry .entry-title {
    font-size: 28px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 20px 0;
}
.blog .entry .entry-title a {
    color: #012970;
    transition: .3s;
}
.blog .entry .entry-title a:hover {
    color: #4154f1;
}
.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #4084fd;
}
.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}
.blog .entry .entry-meta ul li+li {
    padding-left: 20px;
}
.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}
.blog .entry .entry-meta a {
    color: #777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}
.blog .entry .entry-content p {
    line-height: 24px;
}
.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}
.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #4154f1;
    color: #fff;
    padding: 6px 20px;
    transition: .3s;
    font-size: 14px;
    border-radius: 4px;
}
.blog .entry .entry-content .read-more a:hover {
    background: #5969f3;
}
.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: 700;
}
.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}
.blog .entry .entry-content blockquote p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}
.blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #012970;
    margin-top: 20px;
    margin-bottom: 20px;
}
.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}
.blog .entry .entry-footer i {
    color: #0d64fd;
    display: inline;
}
.blog .entry .entry-footer a {
    color: #013289;
    transition: .3s;
}
.blog .entry .entry-footer a:hover {
    color: #4154f1;
}
.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}
.blog .entry .entry-footer .cats li {
    display: inline-block;
}
.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}
.blog .entry .entry-footer .tags li {
    display: inline-block;
}
.blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",  "}
.blog .entry .entry-footer .share {
    font-size: 16px;
}
.blog .entry .entry-footer .share i {
    padding-left: 5px;
}
.blog .entry-single {
    margin-bottom: 30px;
}
.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
}
.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}
.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0;
    padding: 0;
    color: #012970;
}
.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}
.blog .blog-author .social-links a {
    color: rgba(1, 41, 112, .5);
    margin-right: 5px;
}
.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}
.blog .blog-comments {
    margin-bottom: 30px;
}
.blog .blog-comments .comments-count {
    font-weight: 700;
}
.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}
.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}
.blog .blog-comments .comment .comment-img img {
    width: 60px;
}
.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}
.blog .blog-comments .comment h5 a {
    font-weight: 700;
    color: #444;
    transition: .3s;
}
.blog .blog-comments .comment h5 a:hover {
    color: #4154f1;
}
.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #012970;
}
.blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
}
.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #013ca3;
    margin-bottom: 5px;
}
.blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
}
.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
}
.blog .blog-comments .reply-form h4 {
    font-weight: 700;
    font-size: 22px;
}
.blog .blog-comments .reply-form p {
    font-size: 14px;
}
.blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}
.blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #a0aaf8;
}
.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}
.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #a0aaf8;
}
.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}
.blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #012970;
}
.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #013289;
}
.blog .blog-pagination {
    color: #024ed5;
}
.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}
.blog .blog-pagination li {
    margin: 0 5px;
    transition: .3s;
}
.blog .blog-pagination li a {
    color: #012970;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #4154f1;
}
.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
}
.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
}
.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 15px 0;
    color: #012970;
    position: relative;
}
.blog .sidebar .sidebar-item {
    margin-bottom: 30px;
}
.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}
.blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
}
.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: 0 0;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #4154f1;
    color: #fff;
    transition: .3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}
.blog .sidebar .search-form form button i {
    line-height: 0;
}
.blog .sidebar .search-form form button:hover {
    background: #5465f2;
}
.blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
}
.blog .sidebar .categories ul li+li {
    padding-top: 10px;
}
.blog .sidebar .categories ul a {
    color: #012970;
    transition: .3s;
}
.blog .sidebar .categories ul a:hover {
    color: #4154f1;
}
.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaa;
    font-size: 14px;
}
.blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
}
.blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
}
.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: 700;
}
.blog .sidebar .recent-posts h4 a {
    color: #012970;
    transition: .3s;
}
.blog .sidebar .recent-posts h4 a:hover {
    color: #4154f1;
}
.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaa;
}
.blog .sidebar .tags {
    margin-bottom: -10px;
}
.blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
}
.blog .sidebar .tags ul li {
    display: inline-block;
}
.blog .sidebar .tags ul a {
    color: #0257ee;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #d7e6ff;
    display: inline-block;
    transition: .3s;
}
.blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #4154f1;
    background: #4154f1;
}
.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #a5c5fe;
    font-size: 14px;
}
.footer {
    background: #f6f9ff;
    padding: 0 0 30px 0;
    font-size: 14px;
}
.footer .footer-newsletter {
    padding: 50px 0;
    background: #553e93;
    border-top: 1px solid #553e93;
}
.footer .footer-newsletter h4 {
    font-size: 35px;
    margin: 0 auto;
    padding: 0;
    line-height: 1.4;
    font-weight: 700;
    color: #fff;
    width: 50%}
.footer .footer-newsletter p {
    font-size: 18px;
    margin: 0 auto;
    padding: 0;
    line-height: 1.4;
    font-weight: 300;
    color: #fff;
    width: 50%}
.footer .footer-newsletter form {
    margin-top: 20px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #e1ecff;
}
.footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 8px;
    width: calc(100% - 140px);
}
.footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: 0 0;
    font-size: 16px;
    padding: 0 30px;
    margin: 3px;
    background: #4154f1;
    color: #fff;
    transition: .3s;
    border-radius: 4px;
}
.footer .footer-newsletter form input[type=submit]:hover {
    background: #5969f3;
}
.footer .footer-top {
    background: #543e93;
    background-size: contain;
    border-top: 1px solid #553e93;
    border-bottom: 1px solid #553e93;
    padding: 30px 0 30px 0;
}
@media (max-width:992px) {
    .footer .footer-top {
    background-position: center bottom;
}
}.footer .footer-top .footer-info {
    margin-bottom: 30px;
}
.footer .footer-top .footer-info .logo {
    line-height: 0;
    margin-bottom: 15px;
}
.footer .footer-top .footer-info .logo img {
    max-height: 40px;
    margin-right: 6px;
}
.footer .footer-top .footer-info .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: Nunito, sans-serif;
    margin-top: 3px;
}
.footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: Nunito, sans-serif;
}
.footer .footer-top .social-links a {
    font-size: 30px;
    display: inline-block;
    color: rgba(1, 41, 112, .5);
    line-height: 0;
    margin-right: 20px;
    transition: .3s;
}
.footer .footer-top .social-links a:hover {
    color: #fff;
}
.footer .footer-top h4 {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
}
.footer .footer-top .footer-links {
    margin-bottom: 10px;
}
.footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 0;
}
.footer .footer-top .footer-links ul li {    
    padding: 10px 18px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 200;
    /*text-decoration-line: underline;
    text-underline-offset: 10px;*/
}
.footer .footer-top .footer-links ul li:first-child {
    /*padding-top: 0;*/
}
.footer .footer-top .footer-links ul a {
    color: #fff;
    transition: .3s;
    display: inline-block;
    line-height: 1;
}
.footer .footer-top .footer-links ul a:hover {
    color: #ffd524;
}
.footer .footer-top .footer-contact p {
    line-height: 26px;
    font-size: 15px;
}
.footer .copyright {
    text-align: center;
    padding-top: 30px;
    color: #012970;
}
.footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #012970;
}
.header-scrolled .navbar ul li a.scrollto {
    color: #553e93;
}
.header-scrolled .navbar ul li a.active {
    color: #ff443c!important;
}
.img_front {
    max-width: 87%!important;
    height: auto!important;
}
.img_icons {
    width: 40px;
    height: 40px;
}

