.bg_hig_lig_p{
    background: #553e93;
    color: #fff;
    padding: 5px 9px;
}
.bg_hig_lig_h{
    padding: 0px 10px;
    color: #ff5722;
}
.msg_brd_img{
    width: 80%;
    margin: 0 auto;
}
.feature_top{
    padding-top: 100px !important;
}
.home_feature_top{
    margin-top: -135px;
}
.redtie_statistics{
    background: #eeeeff;
    padding: 110px 0px;
}

.features_twoway .feature-box i {
    line-height: 0;
    background: #553e93 !important;
    padding: 4px;
    margin-right: 10px;
    font-size: 24px;
    color: #553e93;
    border-radius: 3px;
    transition: .3s;
}

.f_sz11{ font-size: 11px !important; }
.f_sz12{ font-size: 12px !important; }
.f_sz13{ font-size: 13px !important; }
.f_sz14{ font-size: 14px !important; }
.f_sz15{ font-size: 15px !important; }
.f_sz18{ font-size: 18px !important; }
.f_sz22{ font-size: 22px !important; }
